<template>
  <div class="px-3">
    <vs-table
      v-if="projects.length > 0"
      :data="projects"
      search
    >
      <template slot="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 my-3"
        >
          <h2 class="text-dark ml-4">
            Projetos
          </h2>
          <vs-button
            color="#66ff66"
            text-color="black"
            class="mr-3"
            icon="add"
            @click="createNewProject()"
          >
            Adicionar Projeto
          </vs-button>
        </div>
      </template>
      <template slot="thead">
        <vs-th class="text-center">
          <div class="text-center">
            ID
          </div>
        </vs-th>
        <vs-th>Nome</vs-th>
        <vs-th>Owner</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(project, i) in data"
          :key="i"
          class="border-top"
          :data="project"
        >
          <vs-td class="py-1">
            {{ project.projectId }}
          </vs-td>
          <vs-td class="py-1">
            {{ project.projectName }}
          </vs-td>
          <vs-td class="py-1">
            {{ project.ownerId }}
          </vs-td>
          <vs-td class="py-1">
            <!-- <vs-button small icon="clear"></vs-button> -->
            <vs-button
              small
              icon="edit"
              @click="editProject(project)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      :title="editingProject ? 'Alterar Projeto' : 'Adicionar novo Projeto'"
      :active.sync="projectDialog"
    >
      <vs-row>
        <vs-col vs-lg="2">
          <label class="small font-weight-bold mt-2">ID:</label>
          <vs-input
            v-if="editingProject"
            v-model="obj.projectId"
            class="w-100"
            disabled
          />
          <vs-input
            v-else
            v-model="obj.projectId"
            class="w-100"
            :danger="invalid && !obj.projectId"
            danger-text="Campo obrigatório"
          />
        </vs-col>
        <vs-col vs-lg="5">
          <label
            class="small font-weight-bold mt-2"
          >Nome do projeto:</label>
          <vs-input
            v-model="obj.projectName"
            class="w-100"
            :danger="invalid && !obj.projectName"
            danger-text="Campo obrigatório"
          />
        </vs-col>
        <vs-col vs-lg="3">
          <label
            class="small font-weight-bold mt-2"
          >Owner do Projeto:</label>
          <vs-select
            v-model="obj.ownerId"
            class="w-100"
            :danger="invalid && !obj.ownerId"
            danger-text="Campo obrigatório"
          >
            <vs-select-item
              v-for="(item, i) in owners"
              :key="i"
              :value="item.id"
              :text="`${item.id}: ${item.entityName}`"
            />
          </vs-select>
        </vs-col>
        <vs-col vs-lg="2">
          <label class="small font-weight-bold mt-2">Tipo:</label>
          <vs-input
            v-model="obj.projectType"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="4  ">
          <label
            class="small font-weight-bold mt-2"
          >Tag do projeto:</label>
          <vs-input
            v-model="obj.projectTag"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="5">
          <label
            class="small font-weight-bold mt-2"
          >Tags adicionais:</label>
          <vs-input
            v-model="obj.aditionalTags"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="3">
          <label
            class="small font-weight-bold mt-2"
          >Contagem inicial:</label>
          <vs-input
            v-model="obj.initCount"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="5">
          <label
            class="small font-weight-bold mt-2"
          >URL do projeto:</label>
          <vs-input
            v-model="obj.projectUrl"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="4">
          <label
            class="small font-weight-bold mt-2"
          >Google Analytics:</label>
          <vs-input
            v-model="obj.gacode"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="3">
          <label
            class="small font-weight-bold mt-2"
          >Projeto Master:</label>
          <vs-select
            v-model="obj.masterProject"
            class="w-100"
          >
            <vs-select-item
              v-for="(item, i) in projects"
              :key="i"
              :value="item.projectId"
              :text="`${item.projectId}: ${item.projectName}`"
            />
          </vs-select>
        </vs-col>
      </vs-row>

      <vs-divider class="my-4" />

      <vs-row class="mt-0">
        <vs-col vs-lg="4">
          <label class="small font-weight-bold">Email de envio:</label>
          <vs-input
            v-model="obj.emailSender"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="4">
          <label class="small font-weight-bold">Nome do remetente:</label>
          <vs-input
            v-model="obj.senderName"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="4">
          <label class="small font-weight-bold">Título do email:</label>
          <vs-input
            v-model="obj.mailTitle"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="12">
          <label
            class="small font-weight-bold mt-2"
          >Mensagem de compartilhamento:</label>
          <vs-textarea
            v-model="obj.sharingMessage"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <div class="d-flex justify-content-end align-items-center mt-3">
        <p
          v-if="invalid"
          class="text-danger mb-0 mr-3"
        >
          Preencha os campos obrigatórios!
        </p>
        <vs-button
          class="mr-2"
          @click="projectDialog = false"
        >
          Cancelar
        </vs-button>
        <vs-button
          color="#66ff66"
          text-color="black"
          @click="saveProject()"
        >
          <span v-if="editingProject">Alterar</span>
          <span v-else>Salvar</span>
          Projeto
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Config */
import { baseUrl } from '@/global';

/* Services */
import axios from 'axios';

import System from '@/services/system';

const systemService = new System();

export default {
  name: 'ProjectList',
  data: () => ({
    title: 'SurvTable',
    projects: [],
    owners: [],
    obj: {},
    projectDialog: false,
    editingProject: false,
    invalid: false,
  }),
  computed: {
    ownerId(){
      return this.$store.state.ownerID
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  mounted() {
    this.getProjects();
    this.getOwners();
  },
  methods: {
    getProjects() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getProjectsByOwner(this.ownerId, this.token)
        .then((resp) => {
          this.projects = resp.data.data;

          this.projects.sort(function (a, b) {
            return a.projectId - b.projectId;
          })

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    getOwners() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getOwners()
        .then((resp) => {
          this.owners = resp.data.data;

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);

          alert(err);
        });
    },
    resetDialog() {
      this.invalid = false;
      this.obj = {};
    },
    editProject(project) {
      this.resetDialog();
      this.obj = project;
      this.editingProject = true;
      this.projectDialog = true;
    },
    createNewProject() {
      this.resetDialog();
      this.editingProject = false;
      this.projectDialog = true;
    },
    saveProject() {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/Projects`;

      if (!this.obj.projectId || !this.obj.projectName || !this.obj.ownerId) {
        this.$store.dispatch('set_isLoadingActive', false);
        this.invalid = true;
        return;
      }

      this.replaceNullForEmptyString();

      const object = this.obj;

      object.ownerId = parseInt(object.ownerId);
      object.projectId = parseInt(object.projectId);
      object.projectType = parseInt(object.projectType);
      object.initCount = parseInt(object.initCount);

      axios
        .post(url, object)
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          let term = this.editingProject ? 'atualizado' : 'criado';
          this.$vs.notify({
            title: `Project ${term} com sucesso`,
          });
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.projectDialog = false;
          this.editingProject = false;
          this.getProjects();
        });
    },
    replaceNullForEmptyString(){
      if (!this.obj.initCount) this.obj.initCount = 0
      if (!this.obj.gacode) this.obj.gacode = ''
      if (!this.obj.projectTag) this.obj.projectTag = ''
      if (!this.obj.projectUrl) this.obj.projectUrl = ''
      if (!this.obj.projectTitle) this.obj.projectTitle= ''
      if (!this.obj.aditionalTags) this.obj.aditionalTags = ''
      if (!this.obj.projectType) this.obj.projectType = 1
      if (!this.obj.masterProject) this.obj.masterProject = null
      if (!this.obj.emailSender) this.obj.emailSender = ''
      if (!this.obj.senderName) this.obj.senderName = ''
      if (!this.obj.mailTitle) this.obj.mailTitle= ''
      if (!this.obj.sharingMessage) this.obj.sharingMessage= ''
    }
  },
};
</script>

<style></style>
